import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select"; // Import react-select and SingleValue type
import { create } from "../api/orders";
import { toast } from "react-toastify";
import { convertDateToTimeLocal } from "../utils/time";
import { IAirportOption } from "../types/Airport";
import { useAirports } from "./hooks/useAirports";

const AddOrder = () => {
  const [formData, setFormData] = useState({
    date: "",
    from: "",
    to: "",
    weight: "",
    numberOfItems: "",
    price: "",
    orderType: "send",
    comment: "",
  });

  const { airportOptions } = useAirports();

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (
    selectedOption: SingleValue<IAirportOption>,
    { name }: { name: string },
  ) => {
    setFormData({
      ...formData,
      [name]: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const submissionData = {
        ...formData,
        date: new Date(formData.date),
      };

      const response = await create(submissionData);
      console.log(response);
      toast.success("Order added successfully!");
      navigate(
        formData.orderType === "send" ? "/send-order" : "/deliver-order",
      );
    } catch (error) {
      toast.error(
        error.response && error.response.data
          ? error.response.data.error || "An error occurred"
          : "An error occurred",
      );
      console.error("Error adding order:", error);
    }
  };

  React.useEffect(() => {
    setFormData({
      ...formData,
      date: convertDateToTimeLocal(new Date()),
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center mt-12 animate-fadeIn">
      <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-8">
        Добавить новый заказ
      </h1>
      <div className="card w-full max-w-xs sm:max-w-sm shadow-2xl bg-base-100">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  Тип заказа
                </span>
              </label>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="orderType"
                    value="send"
                    checked={formData.orderType === "send"}
                    onChange={handleChange}
                    className="radio radio-primary"
                  />
                  <span className="ml-2">Отправка</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="orderType"
                    value="deliver"
                    checked={formData.orderType === "deliver"}
                    onChange={handleChange}
                    className="radio radio-primary"
                  />
                  <span className="ml-2">Перевозка</span>
                </label>
              </div>
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Дата</span>
              </label>
              <input
                type="datetime-local"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Откуда</span>
              </label>
              <Select
                name="from"
                options={airportOptions}
                value={airportOptions.find(
                  (option) => option.value === formData.from,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, { name: "from" })
                }
                className="select-bordered text-sm sm:text-base"
                placeholder="Аэропорт вылета"
                isSearchable={true} // Enables search
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Куда</span>
              </label>
              <Select
                name="to"
                options={airportOptions}
                value={airportOptions.find(
                  (option) => option.value === formData.to,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, { name: "to" })
                }
                className="select-bordered text-sm sm:text-base"
                placeholder="Аэропорт прилета"
                isSearchable={true} // Enables search
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  Вес (кг)
                </span>
              </label>
              <input
                type="number"
                name="weight"
                value={formData.weight}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder=""
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  {formData.orderType === "send"
                    ? "Количество вещей"
                    : "Свободных мест"}
                </span>
              </label>
              <input
                type="number"
                name="numberOfItems"
                value={formData.numberOfItems}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder=""
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  Цена (тенге)
                </span>
              </label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder=""
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  Описание заказа
                </span>
              </label>
              <input
                type="text"
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder=""
              />
            </div>

            <div className="form-control mt-6">
              <button
                type="submit"
                className="btn bg-gradient-to-r from-blue-500 to-green-500 text-white w-full sm:w-auto py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
              >
                Добавить заказ
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="h-32"></div>
    </div>
  );
};

export default AddOrder;
