import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select"; // Import react-select and SingleValue type
import { create, deleteOrder, fetch } from "../api/orders";
import { toast } from "react-toastify";
import { convertDateToTimeLocal } from "../utils/time";
import { IAirportOption } from "../types/Airport";
import { useAirports } from "./hooks/useAirports";
import { useSelector } from "react-redux";
import { userSelector } from "../redux/reducers/userSlice";
import { IOrder } from "../types/Order";
import Order from "./Order";

const MyOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [orders, setOrders] = React.useState<IOrder[]>([]); // Default to empty array
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [visiblePhone, setVisiblePhone] = React.useState<{
    [key: string]: boolean;
  }>({}); // Track phone visibility by order ID

  const { data: userData } = useSelector(userSelector);

  // console.log(userData);

  const getOrders = async () => {
    try {
      setLoading(true);
      setError(""); // Reset error state before fetching
      const data = await fetch({ filters: { userId: userData?.id } });
      setOrders(data.orders || []); // Fallback to an empty array if no data is returned
    } catch (err) {
      console.error(err);
      setError("Failed to fetch orders");
      setOrders([]); // Set to an empty array in case of error
    } finally {
      setLoading(false); // Stop loading after fetching
    }
  };

  React.useEffect(() => {
    if (!loading) getOrders();
  }, [userData]);

  const onDelete = async (order: IOrder) => {
    console.log("order", order);
    try {
      setLoading(true);
      setError(""); // Reset error state before fetching
      await deleteOrder(order._id);
      await getOrders();
      toast.success("Order was removed successfully!");
    } catch (err) {
      console.error(err);
      setError("Failed to delete order");
      setOrders([]); // Set to an empty array in case of error
      toast.error(`Error while deleting order: ${err}`);
    } finally {
      setLoading(false); // Stop loading after fetching
    }
  };

  return (
    <div className="container mx-auto flex flex-col items-center mt-12 min-h-screen px-4 animate-fadeIn">
      <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-8">
        Мои заказы
      </h1>
      <div className="container mx-auto flex flex-col items-center mt-2 min-h-screen px-0">
        <div className="flex flex-col items-center w-[100%]">
          {loading ? (
            <div className="flex h-screen animate-fadeOut">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 mt-8"></div>
            </div>
          ) : orders.length > 0 ? (
            orders.map((order) => (
              <Order
                key={order._id}
                order={order}
                showDelete
                onDelete={onDelete}
                isTypeVisible
              />
            ))
          ) : (
            <p className="text-center text-gray-500 mt-4 mb-4">
              Заказов не найдено
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
