import { useEffect, useState } from "react";
import { IAirport, IAirportOption } from "../../types/Airport";
import { fetchAirports } from "../../api/airport"; // Renamed to avoid conflict

export const useAirports = () => {
  const [airports, setAirports] = useState<IAirport[]>([]);
  const [airportOptions, setAirportOptions] = useState<IAirportOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAirportData = async () => {
      try {
        const airportsResult: IAirport[] = await fetchAirports(); // Fetches airport data from API
        setAirports(airportsResult);

        setAirportOptions(
          airportsResult.map((airport: IAirport) => ({
            value: airport.code,
            label: `${airport.code} - ${airport.name}`,
          })),
        );

        setLoading(false);
      } catch (err) {
        console.error("Error fetching airports:", err);
        setError("Failed to fetch airports");
        setLoading(false);
      }
    };

    fetchAirportData();
  }, []);

  return { airports, airportOptions, loading, error };
};
