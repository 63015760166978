import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { IOrder } from "../types/Order";
import { fetch } from "../api/orders";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { userSelector } from "../redux/reducers/userSlice";
import { useSelector } from "react-redux";
import Order from "./Order";

const OrdersList: React.FC<{
  passedFilters?: any;
  isSearchVisible?: boolean;
}> = ({ passedFilters, isSearchVisible = true }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const filters = location.state || passedFilters;

  const [orders, setOrders] = React.useState<IOrder[]>([]); // Default to empty array
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [visiblePhone, setVisiblePhone] = React.useState<{
    [key: string]: boolean;
  }>({}); // Track phone visibility by order ID

  const { data: userData } = useSelector(userSelector);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        setError(""); // Reset error state before fetching
        const data = await fetch(filters);
        setOrders(data.orders || []); // Fallback to an empty array if no data is returned
      } catch (err) {
        console.error(err);
        setError("Failed to fetch orders");
        setOrders([]); // Set to an empty array in case of error
      } finally {
        setLoading(false); // Stop loading after fetching
      }
    };

    if (!loading) fetchOrders();
  }, [filters]); // Re-run the effect only if filters change

  // Toggle phone visibility
  const showPhone = (orderId: string) => {
    if (visiblePhone[orderId]) return;

    setVisiblePhone((prev) => ({
      ...prev,
      [orderId]: true,
    }));

    ReactGA.event({
      category: "Order",
      action: "Phone Reveal",
      label: `Order ${orderId}, user ${userData?.name}`,
    });
  };

  // Handle loading state
  if (loading) {
    if (!isSearchVisible)
      return (
        <div className="flex h-screen animate-fadeOut">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 mt-8"></div>
        </div>
      );
    return (
      <div className="flex justify-center items-center h-screen animate-fadeOut">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  // Handle error state
  if (error) {
    toast.error(`Error while loading orders: ${error}`);
    return <p className="text-center text-red-500">{error}</p>;
  }

  // Render the orders with fade-in animation
  return (
    <div className="container mx-auto flex flex-col items-center mt-12 min-h-screen px-0 animate-fadeIn">
      {isSearchVisible && (
        <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-8">
          Заказы
        </h1>
      )}
      <div className="flex flex-col items-center w-[100%]">
        {orders.length === 0 ? (
          isSearchVisible ? (
            <p className="text-center text-gray-500 mt-4 mb-4">
              Заказов не найдено
            </p>
          ) : (
            <></>
          )
        ) : (
          orders.map((order: IOrder) => (
            <Order order={order}/>
          ))
        )}
        {isSearchVisible && (
          <button
            onClick={() => navigate(-1)}
            className="btn bg-gradient-to-r from-blue-500 to-green-500 text-white w-full sm:w-auto py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
          >
            Новый поиск
          </button>
        )}
      </div>
    </div>
  );
};

export default OrdersList;
