import React, { useEffect, useState } from "react";
import { getProfile } from "../api/user";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserData } from "../redux/reducers/userSlice";

const Profile = () => {
  const [userProfile, setUserProfile] = useState({
    name: "",
    telephone: "",
    completedOrders: 0,
    rating: 0,
    email: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch(); // Redux dispatch hook
  const navigate = useNavigate(); // React Router hook

  const handleLogout = () => {
    dispatch(clearUserData()); // Clear user data from the store
    navigate("/login"); // Redirect to the login page
    toast.success("Logged out successfully!");
  };

  const handleShowMyOrders = () => {
    navigate("/my-orders");
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getProfile();
        setUserProfile(data); // Update the state with fetched profile data
      } catch (error) {
        setError(error.message); // Handle error
        toast.error(
          error.response && error.response.data
            ? error.response.data.error || "An error occurred"
            : "An error occurred",
        );
      } finally {
        setLoading(false); // Stop the loading indicator
      }
    };

    fetchUserProfile();
  }, []); // Run the effect only once when the component mounts

  // Handle loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen animate-fadeOut">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  // Handle error state
  if (error) {
    toast.error(`Error while loading orders: ${error}`);
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="flex flex-col items-center justify-center mt-12 px-4 animate-fadeIn">
      <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-6 text-gray-800">
        Профиль
      </h1>
      <div className="card w-full max-w-xs sm:max-w-sm shadow-lg rounded-lg bg-white border border-gray-200 transition-all transform duration-300">
        <div className="card-body p-8">
          <div className="mb-4 text-center">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-8">
              {userProfile.name}
            </h2>
            <div className="grid grid-cols-[40px,1fr] items-center gap-4 ">
              <div className="flex justify-center items-center w-10 h-10 bg-blue-100 rounded-full">
                <i className="fas fa-envelope text-blue-600 text-lg"></i>
              </div>
              <p className="text-gray-700 text-left">
                <strong>Email:</strong> {userProfile.email}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-[40px,1fr] items-center gap-4 mb-4">
            <div className="flex justify-center items-center w-10 h-10 bg-green-100 rounded-full">
              <i className="fas fa-phone-alt text-green-500 text-lg"></i>
            </div>
            <p className="text-gray-700 text-left">
              <strong>Телефон:</strong> {userProfile.telephone}
            </p>
          </div>

          <div className="grid grid-cols-[40px,1fr] items-center gap-4 mb-4">
            <div className="flex justify-center items-center w-10 h-10 bg-indigo-100 rounded-full">
              <i className="fas fa-clipboard-check text-indigo-500 text-lg"></i>
            </div>
            <p className="text-gray-700 text-left">
              <strong>Завершенных заказов:</strong>{" "}
              {userProfile.completedOrders}
            </p>
          </div>

          <div className="grid grid-cols-[40px,1fr] items-center gap-4 mb-4">
            <div className="flex justify-center items-center w-10 h-10 bg-yellow-100 rounded-full">
              <i className="fas fa-star text-yellow-500 text-lg"></i>
            </div>
            <p className="text-gray-700 text-left">
              <strong>Рейтинг:</strong> {userProfile.rating} / 5
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 w-[50%]">
        <button
          onClick={handleShowMyOrders}
          className="btn bg-gradient-to-r from-blue-500 to-green-500 text-white w-full sm:w-auto py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
        >
          Мои заказы
        </button>
      </div>
      {/* Logout Button */}
      <div className="mt-8">
        <button
          onClick={handleLogout}
          className="btn bg-red-500 text-white py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
        >
          Выйти из профиля
        </button>
      </div>
      <div className="h-32"></div>
    </div>
  );
};

export default Profile;
