import React from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { IOrder } from "../types/Order";

import ReactGA from "react-ga4";
import { userSelector } from "../redux/reducers/userSlice";
import { useSelector } from "react-redux";

const orderTypeToRussian = (orderType: string) => {
  return orderType === "send" ? "отправка" : "доставка";
};

const Order: React.FC<{
  order: IOrder;
  showDelete?: boolean;
  isTypeVisible?: boolean;
  onDelete?: any;
}> = ({ order, showDelete = false, onDelete, isTypeVisible = false }) => {
  const [visiblePhone, setVisiblePhone] = React.useState<{
    [key: string]: boolean;
  }>({}); // Track phone visibility by order ID

  const { data: userData } = useSelector(userSelector);

  // Toggle phone visibility
  const showPhone = (orderId: string) => {
    if (visiblePhone[orderId]) return;

    setVisiblePhone((prev) => ({
      ...prev,
      [orderId]: true,
    }));

    ReactGA.event({
      category: "Order",
      action: "Phone Reveal",
      label: `Order ${orderId}, user ${userData?.name}`,
    });
  };

  return (
    <div
      key={order._id}
      className="card w-full shadow-2xl bg-base-100 mb-2 sm:mb-3 px-0 py-0 w-[100%] animate-fadeIn"
    >
      <div className="card-body px-4 py-2 w-full">
        <div className="flex flex-col items-center sm:items-start w-full">
          <div className="grid grid-cols-3 items-center gap-x-2 w-full text-lg sm:text-sm font-bold tracking-wide text-primary">
            {/* From City - Left Aligned */}
            <div className="text-left">
              <div>{order.from.city}</div>
              <p className="text-xs text-gray-500">{order.from.code}</p>
            </div>

            {/* Plane Icon - Center Aligned */}
            <div className="text-center text-gray-500">
              {isTypeVisible ? (
                order.orderType === "send" ? (
                  <i className="fas fa-paper-plane text-xl mb-1"></i>
                ) : (
                  <i className="fas fa-inbox text-xl mb-1"></i>
                )
              ) : (
                <i className="fas fa-plane"></i>
              )}
            </div>

            {/* To City - Right Aligned */}
            <div className="text-right">
              <div>{order.to.city}</div>
              <p className="text-xs text-gray-500">{order.to.code}</p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-x-2 w-full text-xs sm:text-sm font-medium mt-2">
            {/* Left Column for Author and Date */}
            <div className="flex flex-col space-y-0.5">
              <p className="text-accent flex items-center break-words text-xs sm:text-sm">
                <i className="fas fa-user mr-1 text-xs sm:text-sm"></i>{" "}
                {order.author.name}
              </p>
              <p className="text-gray-600 flex items-center text-xs sm:text-sm">
                <i className="fas fa-calendar-alt mr-1 text-xs sm:text-sm"></i>{" "}
                {format(new Date(order.date), "dd.MM.yyyy")}
              </p>
              <p className="text-gray-600 flex items-center text-xs sm:text-sm">
                <i className="fas fa-clock mr-1 text-xs sm:text-sm"></i>{" "}
                {format(new Date(order.date), "HH:mm")}
              </p>
            </div>

            {/* Middle Column (Empty for Spacing) */}
            <div></div>

            {/* Right Column for Weight, Items, and Comment */}
            <div className="flex flex-col items-end space-y-0.5">
              <p className="text-xs sm:text-sm text-gray-700 flex items-center">
                {order.weight} кг{" "}
                <i className="fas fa-weight ml-1 text-xs sm:text-sm"></i>
              </p>
              <p className="text-xs sm:text-sm text-gray-700 flex items-center">
                {order.numberOfItems} вещей{" "}
                <i className="fas fa-suitcase ml-1 text-xs sm:text-sm"></i>
              </p>
              {order.comment && (
                <p className="text-xs sm:text-sm text-gray-700 flex items-center">
                  {order.comment.length > 8
                    ? `${order.comment.slice(0, 8)}...`
                    : order.comment}{" "}
                  <i className="fas fa-comment ml-1 text-xs sm:text-sm"></i>
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center w-full mt-2">
            {showDelete ? (
              <button
                onClick={() => onDelete(order)}
                className={`py-1 px-2 rounded-md text-white font-medium text-xs 
          bg-red-500 hover:bg-red-600
          transition duration-300 ease-in-out shadow-xs`}
              >
                Удалить
              </button>
            ) : (
              <button
                onClick={() => showPhone(order._id)}
                className={`py-1 px-2 rounded-md text-white font-medium text-xs 
            ${visiblePhone[order._id] ? "bg-green-400 hover:bg-green-500" : "bg-orange-400 hover:bg-orange-500"} 
            transition duration-300 ease-in-out shadow-xs`}
              >
                {visiblePhone[order._id] ? (
                  <a
                    href={`tel:${order.author.telephone}`}
                    className="text-white"
                  >
                    {order.author.telephone}
                  </a>
                ) : (
                  "Показать телефон"
                )}
              </button>
            )}
            <p className="text-right text-lg sm:text-sm font-bold text-primary ml-2">
              {order.price} ₸
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
