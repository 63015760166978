import { useEffect, useState } from "react";
import { IAirport, IAirportOption } from "../../types/Airport";
import { fetchCities } from "../../api/city";

export const useCities = () => {
  const [cities, setCities] = useState<IAirportOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAirportData = async () => {
      try {
        const airportsResult: IAirport[] = await fetchCities(); // Fetches airport data from API

        setCities(
          airportsResult.map((airport: IAirport) => ({
            value: airport.city,
            label: `${airport.city} - ${airport.country}`,
          })),
        );

        setLoading(false);
      } catch (err) {
        console.error("Error fetching airports:", err);
        setError("Failed to fetch airports");
        setLoading(false);
      }
    };

    fetchAirportData();
  }, []);

  return { cities, loading, error };
};
