import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../api/auth";
import { setData, setToken } from "../../redux/reducers/userSlice";
import { toast } from "react-toastify";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");

    try {
      const { token, user } = await login(email, password);

      dispatch(setToken(token));
      dispatch(setData(user));

      navigate("/send-order");
    } catch (err) {
      toast.error(err?.response?.data?.error ?? err.message); // Show error toast
      console.error("Login failed:", err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mt-12 animate-fadeIn">
      <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-8">
        Alysca
      </h1>
      <div className="card w-full max-w-xs sm:max-w-sm shadow-2xl bg-base-100">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Email</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                className="input input-bordered text-sm sm:text-base"
                required
              />
            </div>
            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Пароль</span>
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder=""
                className="input input-bordered text-sm sm:text-base"
                required
              />
            </div>
            {error && (
              <p className="text-red-500 text-center text-sm sm:text-base">
                {error}
              </p>
            )}
            <div className="form-control mt-6">
              <button
                type="submit"
                className="btn bg-gradient-to-r from-blue-500 to-green-500 text-white w-full sm:w-auto py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
              >
                Войти
              </button>
            </div>
            <div className="mt-4">
              <p className="text-center text-sm sm:text-base">
                Не зарегистрированы?{" "}
                <button
                  onClick={() => navigate("/register")}
                  className="text-blue-500 hover:text-blue-700 transition-colors"
                >
                  Создайте аккаунт
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="h-32"></div>
    </div>
  );
}

export default Login;
