import axiosInstance from "./axiosConfig";

export const fetch = async (filters: { filters?: any } = {}) => {
  const response = await axiosInstance.get("/orders", {
    params: filters || {}, // Send optional filters as query params or an empty object
  });
  return response.data;
};

export const create = async (order: any) => {
  const response = await axiosInstance.post("/orders", { ...order });
  return response.data;
};

export const deleteOrder = async (orderId: string) => {
  const response = await axiosInstance.delete(`/orders/${orderId}`);
  return response.data;
};
