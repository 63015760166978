import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga4";
import AddOrder from "../components/AddOrder";
import Profile from "../components/Profile";
import Login from "../components/auth/Login";
import BottomBar from "../components/layout/BottomBar";
import AuthGuard from "../guards/AuthGuard";
import SearchPage from "../components/SearchPage";
import OrdersList from "../components/OrdersList";
import Register from "../components/auth/Register";
import MyOrders from "../components/MyOrders";

ReactGA.initialize("G-WYNX40HW6K");

function App() {
  const location = useLocation();

  const hideTabBarRoutes = ["/login"];

  React.useEffect(() => {
    // Track page view when route changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]); // Re-run the effect when location changes

  return (
    <div className="App">
      <Routes>
        {/* Protected Routes */}
        <Route path="/" element={<Navigate to="/send-order" />} />
        <Route
          path="/send-order"
          element={
            <AuthGuard>
              <SearchPage orderType="send" />
            </AuthGuard>
          }
        />
        <Route
          path="/deliver-order"
          element={
            <AuthGuard>
              <SearchPage orderType="deliver" />
            </AuthGuard>
          }
        />
        <Route
          path="/orders"
          element={
            <AuthGuard>
              <OrdersList />
            </AuthGuard>
          }
        />
        <Route
          path="/add-order"
          element={
            <AuthGuard>
              <AddOrder />
            </AuthGuard>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthGuard>
              <Profile />
            </AuthGuard>
          }
        />

        <Route
          path="/my-orders"
          element={
            <AuthGuard>
              <MyOrders />
            </AuthGuard>
          }
        />

        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>

      {!hideTabBarRoutes.includes(location.pathname) && <BottomBar />}
    </div>
  );
}

export const AppRoutes = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};
